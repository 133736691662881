import router from '@/router/index'
import store from '@/store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { SetToken, GetToken } from '@/utils/auth'
import config from '@/components/Config'
import { omit } from 'lodash'

NProgress.configure({ showSpinner: false })

const ssoUrl = config.ssoUrl
const siteUrl = config.siteUrl
let flag = true
router.beforeEach((to, from, next) => {
  if (GetToken()) {
    next()
    if (flag) {
      flag = false
      const roles = null
      store.dispatch('GenerateRoutes', { roles }).then(accessRoutes => {
        // 根据roles权限生成可访问的路由表
        // //debugger
        accessRoutes.forEach(route => {
          try {
            router.addRoute(route) // 动态添加可访问路由表
          } catch (err) {
            console.log(err, 'err')
          }
        })
        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
      })
    }
  } else if (to.query !== undefined && to.query.token !== undefined) {
    // debugger
    // 校验Token
    // 校验成功返回xAccessToken
    SetToken(to.query.token).then(() => {
      if (GetToken()) {
        const params = omit(to.query, 'token')
        router.replace({ path: to.path, query: params })
        next()
        if (flag) {
          flag = false
          const roles = null
          store.dispatch('GenerateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            // //debugger
            accessRoutes.forEach(route => {
              router.addRoute(route) // 动态添加可访问路由表
            })
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
        }
      } else {
        location.href = ssoUrl + '/login?url=' + siteUrl
      }
    })
  } else {
    if (!GetToken()) {
      location.href = ssoUrl + '/login?url=' + siteUrl
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
