<script>
let ssoUrl = ''
let apiUrl = ''
let appId = ''
let siteUrl = ''

switch (process.env.NODE_ENV) {
  case 'development':
    ssoUrl = 'https://sso.douledi.com' // sso登录授权
    // apiUrl = 'http://dev.api.douledi.com:8083' // api接口地址
    apiUrl = 'https://api.douledi.com' // api接口地址
    siteUrl = 'http://dev.adm.douledi.com:8082' // 本系统地址
    appId = 'b24a1abf465545398a3f5d116b3ebd2d' // 本系统站点ID
    break
  case 'sit':
    ssoUrl = 'https://sit-sso.hogolife.com'
    apiUrl = 'https://sit-api.hogolife.com'
    siteUrl = ''
    appId = '19037bf7252b446f83ec219e991ae65a'
    break
  case 'uat':
    ssoUrl = 'https://uat-sso.hogolife.com'
    apiUrl = 'https://uat-api.hogolife.com'
    siteUrl = ''
    appId = '19037bf7252b446f83ec219e991ae65a'
    break
  case 'production':
  ssoUrl = 'https://sso.douledi.com' // sso登录授权
    apiUrl = 'https://api.douledi.com' // api接口地址
    siteUrl = 'https://adm.douledi.com' // 本系统地址
    appId = 'b24a1abf465545398a3f5d116b3ebd2d' // 本系统站点ID
    break
}
export default {
  ssoUrl,
  apiUrl,
  appId,
  siteUrl
}
</script>
