import axios from 'axios'
import Config from '@/components/Config.vue'
import { TOKEN_KEY } from '@/utils/constant'
import { Notification, Message } from 'element-ui'

// 创建axios实例
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'
const service = axios.create({
  baseURL: Config.apiUrl,
  withCredentials: true,
  // 超时
  timeout: 1000 * 60 * 60 * 5
})
// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    // 让每个请求携带自定义token 请根据实际情况自行修改
    const isToken = (config.headers || {}).isToken === false
    if (sessionStorage.getItem(TOKEN_KEY) && !isToken) {
      config.headers['X-Access-Token'] = sessionStorage.getItem(TOKEN_KEY) || ''
      config.headers.appId = Config.appId
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              const params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    if (res.status === 200) {

      if (res.config.responseType === 'blob') {
        // 文件流，直接 return
        return res
      } else {
        // 获取返回代码
        const code = res.data.code || 200
        // 获取错误信息
        // const msg = res.data.msg || res.data.message
        if (code === 401) {
          // 登录已过期
          sessionStorage.removeItem(TOKEN_KEY)
          location.href =
            Config.ssoUrl + '/login/?url=' + encodeURIComponent(Config.siteUrl)
        } else {
          return res
        }
      }
    } else {
      Message.error(res.data.message || '网络异常')
      return Promise.reject(res)
    }
  },
  error => {
    const errorStatus = error.response.status
    if (errorStatus === 666 || errorStatus === 401) {
      // 删除token
      sessionStorage.removeItem(TOKEN_KEY)
      location.href =
        Config.ssoUrl + '/login/?url=' + encodeURIComponent(Config.siteUrl)
    } else if (errorStatus >= 500 && errorStatus < 600) {
      Message.error('服务器忙碌，请稍后再试')
    } else if (errorStatus >= 400 && errorStatus < 500) {
      Message.error('客户端忙碌，请稍后再试')
    }
    return Promise.reject(error)
  }
)

export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    service.get(url, { params }).then(
      response => {
        resolve(response)
      },
      err => {
        reject(err)
      }
    )
  })
}
export const post = (
  url,
  data
) => {
  return new Promise((resolve, reject) => {
    service.post(url, data).then(
      response => {
        resolve(response)
      },
      err => {
        reject(err)
      }
    )
  })
}
export const getStream = (url,data) => {
  return service({
    url: url,
    method: 'get',
    params: data,
    responseType: 'stream'
  });
};
export const download = (url,data) => {
  return service({
    url: url,
    method: 'post',
    params: data,
    responseType: 'blob'
  });
};
export default service
