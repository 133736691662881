import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

/* Layout */

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '*',
    component: () => import('@/views/error/404.vue'),
    hidden: true
  },
  {
    path: '/system/test/page',
    component: () => import('@/views/test/page/index.vue'),
    hidden: true
  },
  {
    path: '/system/activiti/form/index',
    name: 'activitiForm',
    component: () => import('@/views/system/activiti/form/index.vue')
    // hidden: true
  },
  {
    path: '/',
    component: () => import('@/components/Home.vue'),
    hidden: true,
    children: [
      {
        path: '/',
        component: () => import('@/views/system/index.vue'),
        hidden: true
      },
      {
        path: '/system/ai/translation',
        name: 'translation',
        component: () => import('@/views/system/ai/translation.vue'),
        meta: {
          title: '翻译'
        }
      },
      {
        path: '/system/ai/txtImg',
        name: 'txtImg',
        component: () => import('@/views/system/ai/txtImg.vue'),
        meta: {
          title: '文生图'
        }
      },
      {
        path: '/system/ai/txtRedact',
        name: 'txtRedact',
        component: () => import('@/views/system/ai/txtRedact.vue'),
        meta: {
          title: '文本编辑'
        }
      },
      {
        path: '/system/ai/ocrImg',
        name: 'ocrImg',
        component: () => import('@/views/system/ai/ocrImg.vue'),
        meta: {
          title: 'OCR识别'
        }
      },
      {
        path: '/system/ai/imgRedact',
        name: 'imgRedact',
        component: () => import('@/views/system/ai/imgRedact.vue'),
        meta: {
          title: '图片编辑'
        }
      }
    ]
  }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
